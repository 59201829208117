<template>
  <div>
    <footer class="ihamba_footer">
      <div class="container">
        <div class="row">
          <!-- <div class="col-md-4">
            <h2>SIGN UP TO OUR NEWSLETTER</h2>
            <p style="font-size:12px">We will never overload you with emails but we do share a newsletter once a month that will help you plan your next safari.</p>
            <div>
              <el-form
                :model="newsLetterForm"
                :rules="rules"
                label-position="top"
                ref="newsLetterForm"
                class="demo-newsLetterForm"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="full_name">
                      <el-input
                        v-model="newsLetterForm.full_name"
                        placeholder="Full Name"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </div>
                 
                  <div class="col-md-12">
                    <el-form-item prop="email">
                      <el-input
                        v-model="newsLetterForm.email"
                        placeholder="Email"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item>
                  <el-button
                    style="background-color: #73664C; border: 2px solid #73664C; color: white; width: 100%;"
                    @click="submitForm('newsLetterForm')"
                    size="small"
                  >SIGN UP <i class="el-icon-d-arrow-right"></i></el-button>
                </el-form-item>
              </el-form>
            </div>
          </div> -->
          <div class="col-md-4">
            <h2>TATA AFRICA SAFARIS</h2>
            <div>
              <!-- <div class="mb-1">
                <span>CONTACT</span>
              </div> -->
              <ul>
                
                <li><a href="https://wa.me/+256766274001/?text=Hello, Tata Africa Safaris" class="float" target="_blank">+256 766274001</a></li>
                
                <li>info@tataafricasafaris.com</li>
                <li>Kampala, Uganda</li>

              </ul>
              
            </div>
          
          </div>
          <div class="col-md-4">
            <h2>QUICK LINKS</h2>
            <ul>
              <li @click="$router.push({path:'/about-us'})">About Us</li>
              <!-- <li>Meet the Team</li> -->
              <li>Accommodation</li>
              <li>Privacy Policy</li>
              <li>Terms and conditions</li>
            </ul>
          </div>
          <div class="col-md-4">
            <h2>EXPERIENCES</h2>
            <ul>
              <li  @click="$router.push({path:'/safari-packages?whereTo=Uganda'})">Uganda</li>
              
              <li  @click="$router.push({path:'/safari-packages?whereTo=Kenya'})">Kenya</li>
              <li  @click="$router.push({path:'/safari-packages?whereTo=Tanzania'})">Tanzania</li>
            </ul>
          </div>

        </div>
      </div>
    </footer>
    <div class="copyright py-3">
      <div class="container">
        <hr style="width: 100%; background-color: #73664C;" />
        <div class="copyrighttop">
          <ul>
            <li><small style="color:white">Follow us on:</small> </li>
            <li><a
                style="color: white;"
                href="https://www.facebook.com/tataafricasafaris"
                target="_blank"
                title="Facebook"
              ><i class="fab fa-facebook-f"></i></a></li>
            <li><a
                style="color: white;"
                href="https://x.com/tatasafaris"
                target="_blank"
                title="Twitter"
              ><i class="fab fa-twitter"></i></a></li>
            <li><a
                style="color: white;"
                href="https://www.instagram.com/tata_africa_safaris/"
                target="_blank"
                title="Instagram"
              ><i class="fab fa-instagram"></i></a></li>
          </ul>
        </div>
        <div class="copyrightbottom">
          <p> <small>© <span id="year">{{ currentYear }}</span> Tata Africa Safaris . All Rights Reserved</small> </p>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ["1"],
      activeName: "1",
      isSubmitting: false,
      newsLetterForm: {
        full_name: "",
        email: "",
      },
      currentYear: new Date().getFullYear(),

      rules: {
        full_name: [
          {
            required: true,
            message: "Full Name is required",
            trigger: "blur",
          },
        ],
       
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isSubmitting = true;
            this.isLoading = true;
            let request = await this.$http.post(
              `api/newsletter/add`,
              this.newsLetterForm
            );
            if (
              request.data.success &&
              request.data.message == "ADDED_SUCCESSFULLY"
            ) {
              this.$notify({
                title: "Success",
                message: "Subscribed successfully",
                type: "success",
              });
              this.$refs[formName].resetFields();
            } else if( request.data.message == "ALREADY_SUBSCRIBED"){
              this.$notify({
                title: "Success",
                message: "You are Already Subscribed",
                type: "success",
              });
            }else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isSubmitting = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isSubmitting = false;
            this.$notify({
              title: "Submission Failed",
              message: "Unable to Submit Message, Please try again",
              type: "error",
            });
          } finally {
            this.isSubmitting = false;
          }
        } else {
          return false;
        }
      });
    },
 
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
footer {
  height: 100%;
  width: 100%;
  color: rgb(218, 216, 216);
  background-color: #8B652D;
  padding-top: 50px;
}
footer h2 {
  font-size: 0.8em;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
footer ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
footer ul li {
  display: block;
  color: #bfbfbf;
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 10px;
  cursor: pointer;
}
footer ul li a {
  color: #bfbfbf;
  font-weight: 300;
  display: block;
  padding: 5px 0px;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.fab.fa-twitter{
	font-family:sans-serif;
}
.fab.fa-twitter::before{
	content:"𝕏";
	font-size:1.2em;
}
footer ul li a:hover {
  color: #efb35a;
}
footer ul li:hover {
  color: #efb35a;
}
.copyright ul li {
  display: inline-block;
  padding: 0 10px;
}
a.facebook {
  color: #fff;
  font-size: 16px;
}
a.facebook:hover {
  color: #0048ff;
}
.copyrighttop {
  float: right;
}
.copyrightbottom {
  float: left;
}
.copyright {
  background: #8B652D;
  color: #fff;
  border-top: 1px solid #8B652D;
}
.copyrightbottom p {
  color: #fff;
  letter-spacing: 1px;
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}
.copyrightbottom a {
  color: #0048ff;
  text-decoration: none;
}
.copyrightbottom a:hover {
  color: #fff;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .copyrighttop {
    float: none;
  }
  .copyrightbottom {
    float: none;
  }
  .copyright {
    text-align: center;
    color: grey;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .copyrighttop {
    float: none;
  }
  .copyrightbottom {
    float: none;
  }
  .copyright {
    text-align: center;
    color: grey;
  }
}
</style>