<template>
  <header>
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <div class="nav-flex">
          <div class="text-left theMobileWidth d-flex align-items-center justify-content-between">
            <div class="text-center">
              <a
                class="navbar-brand"
                style="cursor: pointer; display: flex; align-items: flex-start;"
                @click="$router.push({path: '/'})"
              >
                <img
                  alt="Tata Africa Safaris logo"
                  src="../assets/tata-africa-logo-lg.svg"
                >
              </a>
            </div>
            <div class="menu">
              <div @click="openNav()">
                <div>
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                </div>
              </div>
            </div>

          </div>

          <div class="
              nav-list-two
              d-flex
              align-items-center
              justify-content-center
            ">
            <div style="display: flex; justify-content: flex-end;">
              <ul class="navbar-nav">
                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'Home' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/home'})"
                >
                  <span class="nav-link"><strong> Home</strong></span>
                </li>

                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'AboutUs' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/about-us'})"
                >
                  <span class="nav-link"><strong> About us</strong></span>
                </li>

                
                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'Experiences' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/experiences'})"
                >
                  <span class="nav-link"><strong> Our Experiences</strong></span>
                </li>
                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'SafariPackages' }"
                  style="margin:auto;"
                >
                  <el-dropdown
                    trigger="click"
                    @command="handleCommand"
                  >
                    <span class="el-dropdown-link">
                      <span
                        class="nav-link"
                        :class="{ active: $route.name == 'SafariPackages' }"
                        style="font-size:.98em;"
                      >
                        <strong>
                          Destinations<i class="el-icon-arrow-down el-icon--right"></i>
                        </strong>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="Uganda">Uganda</el-dropdown-item>
                      <!-- <el-dropdown-item command="Rwanda">Rwanda</el-dropdown-item> -->
                      <el-dropdown-item command="Kenya">Kenya</el-dropdown-item>
                      <el-dropdown-item command="Tanzania">Tanzania</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>

                <!-- <li
                  class="nav-item"
                  style="margin:auto;"
                >
                  <el-dropdown
                    trigger="click"
                    @command="handleTourIdeas"
                  >
                    <span class="el-dropdown-link">
                      <span
                        class="nav-link"
                        :class="{ active: $route.name == 'TourIdeas' }"
                        style="font-size:.9em"
                       
                      >
                        <strong>
                          Safari Types<i class="el-icon-arrow-down el-icon--right"></i>
                        </strong>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(types, index) in safari_types"
                        :key="index"
                        :command="types"
                      >{{types.title}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li> -->

                <!-- <li
                  class="nav-item"
                  :class="{ active: $route.name == 'TailorMadeSafaris' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/tailor-made-safaris'})"
                >
                  <span class="nav-link"><strong>Customise</strong></span>
                </li> -->


                
                <!-- <li
                  class="nav-item"
                  :class="{ active: $route.name == 'ContactUs' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/blog'})"
                >
                  <span class="nav-link"><strong> Blog</strong></span>
                </li> -->

                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'ContactUs' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/contact-us'})"
                >
                  <span class="nav-link"><strong> Contact us</strong></span>
                </li>

                
               

                <li>
                  
                  <a href="">
                    <button
                      class="btn btn-lg"
                      style="background-color: #856645; color:white; margin-left: 15px;"
                    >Get A  Quote</button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="">
      <el-dialog
        title="Outer Dialog"
        :visible.sync="outerVisible"
        center
      >
        <div
          slot="footer"
          class="dialog-footer"
        ></div>
      </el-dialog>
    </div>

    <el-drawer
      title="I am the title"
      :visible.sync="sidebarDrawer"
      direction="ltr"
      size="70%"
      :with-header="false"
    >
      <div class="sidebar">
        <el-row class="tac">
          <el-col>
          
            <!-- <h5 style="color:#fff; margin-left:10px">Tata Africa Safaris</h5> -->
            <img  style="width: 30%; margin-left:40px;"  src="../assets/tata-africa-safaris-logo-white.svg" alt="" class="text-center">
          
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#8B652D"
              text-color="#fff"
              active-text-color="#ffd04b"
            >
              <el-menu-item
                index="1"
                @click="closeNav()"
              >
                <router-link to="/home">
                  <i class="el-icon-house" style="color:white"></i>
                  <span>HOME</span>
                </router-link>
              </el-menu-item>
              <el-submenu index="2">
                <template slot="title">
                  <i class="el-icon-location" style="color:white"></i>
                  <span>DESTINATIONS</span>
                </template>
                <el-menu-item
                  index="2-1"
                  @click="closeNav()"
                >
                  <router-link to="/safari-packages?whereTo=Uganda">Uganda</router-link>
                </el-menu-item>
                <el-menu-item
                  index="2-2"
                  @click="closeNav()"
                >
                  <router-link to="/safari-packages?whereTo=Kenya">Kenya</router-link>
                </el-menu-item>
                <!-- <el-menu-item
                  index="2-3"
                  @click="closeNav()"
                >
                  <router-link to="/safari-packages?whereTo=Rwanda">Rwanda</router-link>
                </el-menu-item> -->
                <el-menu-item
                  index="2-4"
                  @click="closeNav()"
                >
                  <router-link to="/safari-packages?whereTo=Tanzania">Tanzania</router-link>
                </el-menu-item>
              </el-submenu>

        

              <el-menu-item
                index="5"
                @click="closeNav()"
              >
                <router-link to="/experiences">
                  <i class="el-icon-document" style="color:white"></i>
                  <span>EXPERIENCES</span>
                </router-link>
              </el-menu-item>

              <el-menu-item
                index="6"
                @click="closeNav()"
              >
                <router-link to="/contact-us">
                  <i class="el-icon-menu" style="color:white"></i>
                  <span>CONTACT US</span>
                </router-link>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
    </el-drawer>

  </header>
</template>

<script>
// import SidebarComponent from './sidebar-component.vue';
import jwt_decode from "jwt-decode";
export default {
  // components: {SidebarComponent},
  data() {
    return {
      drawer: false,
      sidebarDrawer: false,
      outerVisible: false,
      innerVisible: false,

      safari_types: [],
    };
  },

  computed: {
    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },

  mounted() {
    this.setCurrentUser();
    this.getSafariTypes();
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    handleCommand(country) {
      this.$router.push({ path: `/safari-packages?whereTo=${country}` });
    },
    handleTourIdeas(tour) {
      // alert(tour);
      // alert(JSON.stringify(tour));
      this.$router.push({ path: `/tour-ideas/${tour.id}/${tour.title}` });
    },
    openNav() {
      this.sidebarDrawer = true;
    },

    closeNav() {
      this.sidebarDrawer = false;
    },

    setCurrentUser() {
      // let userToken = JSON.parse(localStorage.getItem("user"));
      // let decoded = jwt_decode(userToken);
      // this.$store.commit("setCurrentUsername", decoded.data.username);
      // this.$store.commit("setCurrentFullName", decoded.data.fullname);
      // this.$store.commit("setCurrentRole", decoded.data.role);
    },
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      this.sidebarDrawer = false;
      location.reload(true);
    },

    async getSafariTypes() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`api/safari_types/get_all`);
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.safari_types = request.data.safari_types;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
nav {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(84, 84, 84, 0.35) 0px 5px 15px;
  height: 110px;
}
nav a {
  text-decoration: none;
}

nav li {
  color: #8B652D;
  cursor: pointer;
  transition: 0.5s;
  text-transform: uppercase;

 
}

li{
  font-size: .9em;
}
.nav-link {
  color: #8B652D;
  margin-left:20px
}

.active {
  color: #856645 !important;
}

.side-link {
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-list-two {
  list-style: none;
  height: 100%;
  padding: 0px;
}

.nav-flex span:hover {
  color: #856645;
  transition: 0.5s;
}

.menu {
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 2px;
  background-color: #8B652D;
  margin: 6px 0;
  border-radius: 3px;
  transition: 0.4s;
}

.sidebar {
  background-color: #8B652D;
  height: 100%;
  padding-top: 100px;
}

.sidebar a {
  text-decoration: none;
  color: white;
}

.navbar-brand img {
  /* width: 160px; */
  /* margin-top: 100px; */
  /* border-radius: 8px; */
  height: 95px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .menu {
    display: none;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .menu {
    display: none;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .menu {
    display: block;
  }
  .nav-list-two {
    display: none !important;
  }
  .theMobileWidth {
    width: 100% !important;
  }
  .navbar-brand img {
    width: 50px;
    margin-top: 0px;
  }
  .nav-flex {
    height: 30px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .menu {
    display: block;
  }
  .nav-list-two {
    display: none !important;
  }
  .theMobileWidth {
    width: 100% !important;
  }
  .navbar-brand img {
    width: 50px;
    margin-top: 0px;
  }
  .nav-flex {
    height: 30px;
  }
}
</style>

<style>
el-drawer {
  background-color: red;
}
.v-modal {
  z-index: 0 !important;
}
.el-icon-arrow-down:before {
  content: "\e6df";
  color: white;
}
</style>